<template>
  <div v-if="countyFilter" ref="componentRef" class="event-filter__county">
    <p v-if="!inSearchBar" class="p-bold">{{ websiteText("sv.filter.location.selecttext") }}</p>
    <div class="select-list event-filter__select">
      <div
        v-if="countyFilter.options"
        :class="[
          { 'select-list__selected--open': toggle },
          { 'select-list__disabled': selectedDistanceFilter === 'True' },
          'select-list__selected',
        ]"
        @click="toggleShow()"
      >
        {{
          selectedCountyFilter.length === 0 || selectedCountyFilter.includes("remote")
            ? countyFilter.options[0].label
            : selectedCountyFilter.join(', ')
        }}
      </div>
      <ul v-if="toggle" class="select-list__list">
        <li class="select-list__list-item">
          <input
            v-model="searchText"
            type="text"
            :placeholder="websiteText('sv.filter.search.placeholder')"
            class="select-list__search"
          />
        </li>
        <li class="select-list__list-item select-list__header">
          {{ websiteText("sv.filter.county.selecttext") }}
        </li>
        <li
          v-for="(option, index) in filteredOptions"
          :key="index"
          :value="option.id"
          class="select-list__list-item"
          @click="toggleOption(option.id)"
        >
          <input
            type="checkbox"
            :id="'option_' + index"
            v-model="option.selected"
          />
          <label :for="'option_' + index">{{ option.label }}</label>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useFilterStore } from "@/store/filterStore";
  import { storeToRefs } from "pinia";
  import { ref, computed } from "vue";
  import { websiteText } from "@/mixins/websiteTexts";
  import useDetectOutsideClick from "@/helpers/useDetectOutsideClick";

  const toggle = ref<boolean>(false);
  const componentRef = ref();
  const searchText = ref<string>("");

  const toggleShow = () => {
    if (selectedDistanceFilter.value !== "True") {
      toggle.value = !toggle.value;
    } else {
      const el = document.querySelector(".remote-selected input");
      if (el) {
        el.classList.add("warn");
        setTimeout(() => {
          el.classList.remove("warn");
        }, 1000);
      }
    }
  };

  useDetectOutsideClick(componentRef, () => {
    if (toggle.value) toggle.value = false;
  });

  const props = defineProps<{
    inSearchBar?: boolean;
  }>();

  const filterStore = useFilterStore();
  const { countyFilter, selectedCountyFilter, selectedDistanceFilter } = storeToRefs(filterStore);

  const filteredOptions = computed(() => {
    if (props.inSearchBar) {
      return countyFilter.value.options.map(option => ({
        ...option,
        selected: selectedCountyFilter.value.includes(option.id)
      }));
    } else {
      return countyFilter.value.options
        .filter(option => option.label.toLowerCase().includes(searchText.value.toLowerCase()) && option.id !== 'distans' && option.id !== '')
        .map(option => ({
          ...option,
          selected: selectedCountyFilter.value.includes(option.id)
        }));
    }
  });

  const toggleOption = (selected: string) => {
    const option = filteredOptions.value.find(option => option.id === selected);
    if (option) {
      option.selected = !option.selected;
      selectCounty(selected);
    }
  };

  const selectCounty = (selected: string) => {
    const index = selectedCountyFilter.value.indexOf(selected);
    if (index === -1) {
      selectedCountyFilter.value.push(selected);
    } else {
      selectedCountyFilter.value.splice(index, 1);
    }
    
    const searchParams = new URLSearchParams(window.location.search);

    if (props.inSearchBar && selectedCountyFilter.value.includes("distans")) {
      searchParams.set("g_DistanceCourse", "True");
      searchParams.delete("g_County");
      searchParams.delete("g_PremisesPostalCity");
    } else {
      searchParams.delete("g_DistanceCourse");
      searchParams.delete("g_County");

      selectedCountyFilter.value.forEach(county => {
        searchParams.append("g_County", county);
      });
    }

    if (selectedCountyFilter.value.length === 0) {
      searchParams.delete("g_County");
      searchParams.delete("g_PremisesPostalCity");
      searchParams.delete("g_DistanceCourse");
    }

    searchParams.delete("page");
    window.location.hash = "filter";
    window.location.search = searchParams.toString();
  };
</script>